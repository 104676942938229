import avatar1 from './1.png';
import avatar2 from './2.png';
import avatar3 from './3.png';
import avatar4 from './4.png';
import avatar5 from './5.png';
import avatar6 from './6.png';
import avatar7 from './7.png';
import avatar8 from './8.png';
import avatar9 from './9.png';

const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
];

export default avatars;
